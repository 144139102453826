const $ = window.$, _ = window._
import {listenForTrackEvents, trackEventSP, trackViewEvents} from 'js/studios/shared/tracking';
import {parseForbidden} from 'js/studios/shared/http';
import {IntersectionObserverShim} from "js/studios/shared/visibility_observer"

// Set CSRF token
$.ajaxSetup({
  beforeSend: function(xhr) {
    xhr.setRequestHeader('X-CSRFToken', $('[name="csrf-token"]').attr('content'));
  },
  error: function(xhr){
    if (xhr.status == 403){
      parseForbidden(xhr);
    }
  }
})

// we use custom --vh var in some css instead of vh unit to account for mobile oddities where vh unit may be calculated including things like address bar
function setViewPort(){
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}
setViewPort();
window.addEventListener('resize', _.throttle(setViewPort, 300));

window.addEventListener('load', function(){
  // Set up tracking
  listenForTrackEvents()
  trackViewEvents()

  //Displays fixed CTA at bottom of screen on mobile. Need to avoid showing it
  //when the hero is in view so we don't have two primary CTAs side by side.
  if (document.querySelector('.hero_element') && document.querySelector('#mobile_primary_cta_container')) {
    const mobileCtaEl = document.querySelector('#mobile_primary_cta_container')
    const ObserverClass = "IntersectionObserver" in window ? IntersectionObserver : IntersectionObserverShim
    const observer = new ObserverClass(function(entries, _observer) {
      if (entries[0] && entries[0].isIntersecting) {
        mobileCtaEl.classList.remove('visible')
      } else if (entries[0] && !entries[0].isIntersecting) {
        mobileCtaEl.classList.add('visible')
      }
    })
    observer.observe(document.querySelector('.hero_element'))
  }

  // marketing_header.html Open/close navigation on mobile
  const mobileNavTriggerEl = document.querySelector('.mobile-nav-trigger')
  if (mobileNavTriggerEl) {
    const headerWrapperEl = document.querySelector('.header_wrapper')
    const taxNavL3El = document.querySelector('.taxonomy-navigation-l3')
    const taxNavGroupEl = document.querySelector('.taxonomy-navigation-group')
    mobileNavTriggerEl.addEventListener('click', function(ev){
      ev.preventDefault();
      //Collapse any expanded subnavs by default whenever
      //menu is opened or closed. These elements are not present for marketplace makers
      taxNavL3El?.classList.remove('open')
      taxNavGroupEl?.classList.remove('open')

      if (headerWrapperEl.classList.contains('mobile-open')) {
        headerWrapperEl.classList.remove('mobile-open')
        document.body.classList.remove('mobile-navigation-open')
        mobileNavTriggerEl.classList.remove('open')
      } else {
        headerWrapperEl.classList.add('mobile-open')
        document.body.classList.add('mobile-navigation-open') // hides overflow so page doesn't move when menu closes
        mobileNavTriggerEl.classList.add('open')
      }
    })
  }

  // marketing_header.html - Expand top level menus on mobile.
  Array.from(document.querySelectorAll('.mobile-navigation-group-expander')).forEach(el => {
    el.addEventListener('click', function(ev) {
      ev.preventDefault()
      const thisTaxNavGroupEl = el.closest('.taxonomy-navigation-group')
      Array.from(document.querySelectorAll('.taxonomy-navigation-group')).forEach(taxNavGroupEl => {
        if (thisTaxNavGroupEl !== taxNavGroupEl) { taxNavGroupEl.classList.remove('expanded') }
      })
      thisTaxNavGroupEl.classList.toggle('expanded')
    })
  })

  //Expose third level of taxo on mobile
  Array.from(document.querySelectorAll('.second-level-link.expandable')).forEach(el => {
    el.addEventListener('click', function(ev) {
      //If mobile, then we want these links to open sub menus rather than redirect.
      //On desktop, we want them to behave as links.
      if(document.body.classList.contains('mobile-navigation-open')) {
        ev.preventDefault()
        el.parentElement.querySelector('.taxonomy-navigation-l3').classList.add('open')
      }
    })
  })

  //Return from third level taxo to main navigation on mobile
  Array.from(document.querySelectorAll('.taxonomy-l3-close')).forEach(el => {
    el.addEventListener('click', function(ev) {
      ev.preventDefault()
      //Close level 3 menu
      el.closest('.taxonomy-navigation-l3').classList.remove('open')
      //Close all associated parent menus and reset to the default menu open state.
      el.closest(".taxonomy-navigation-group").classList.remove('expanded')
    })
  })

  // Analytics tracking events on clicks inside the main navigation.
  Array.from(document.querySelectorAll('.main-navigation-container a')).forEach(el => {
    el.addEventListener('click', function(_ev){
      trackEventSP('click_nav_item', 'browse', { label: el.getAttribute('title') })
    })
  })


  // Analytics tracking events on footer links
  Array.from(document.querySelectorAll('#marketing-footer a')).forEach(el => {
    el.addEventListener('click', function(_ev) {
      trackEventSP('click_footer_item', 'browse', { label: el.getAttribute('title') })
    })
  })

  // Analytics tracking events for all consultation CTAs
  Array.from(document.querySelectorAll('.consultation_cta, [data-primary-cta]')).forEach(el => {
    el.addEventListener('click', function(_ev) {
      trackEventSP('click_primary_cta', 'lead_capture', {
        'button_location': el.dataset.location,
        'call_to_action': el.text,
      })
    })
  })

  // Analytics tracking on body links
  Array.from(document.querySelectorAll('.body_link, [data-bodylink]')).forEach(el => {
    el.addEventListener('click', function(_ev) {
      const label = el.text ? el.text : el.href // most links have inner text, but some are just an image: for those, use the href as the label
      trackEventSP('click_body_link', 'browse', { label: label })
    })
  })

  // nicely animate href anchor links on newer browsers
  if (window.scrollBy) {
    Array.from(document.querySelectorAll('[data-scrollto]')).forEach(el => {
      el.addEventListener('click', function(ev) {
        ev.preventDefault()
        history.pushState(null, null, el.getAttribute('href'))
        const scrollDestEl = document.querySelector(el.getAttribute('href'))
        const headerWrapperEl = document.querySelector('.header_wrapper')
        const catNavEl = document.querySelector('#category_nav')
        let y = scrollDestEl.getBoundingClientRect().top - headerWrapperEl.getBoundingClientRect().height
        if (catNavEl) { y -= catNavEl.getBoundingClientRect().height }
        window.scrollBy({ left: document.body.scrollLeft, top: y, behavior: 'smooth' })
      })
    })
  }
})
